.custom-maplayer-selector {
  .svg-container {
    &:hover {
      svg {
        polygon,
        path,
        rect:not(:nth-child(1)) {
          fill: #2473bd;
        }
      }
    }
  }
  .maplayer-options {
    position: absolute;
    width: 120px;
    min-height: 32px;
    right: 55px;
    top: 104px;
    background-color: #f7f7f7;
    display: grid;
    grid-template-rows: auto;

    .layer-option {
      cursor: pointer;
      display: grid;
      grid-template-columns: 40px auto;
      &:hover {
        background-color: rgb(190, 190, 190);
      }
      img {
        user-select: none;
        align-self: center;
        width: 20px;
        border-radius: 5px;
        margin-left: 5px;
      }
      div {
        user-select: none;
        align-self: center;
        text-align: end;
        margin-right: 5px;
        font-size: 12px;
      }
    }
  }
}
