.custom-map-control {
  display: block;
  right: 0;
  padding: 12px;
  z-index: 10;
  margin-top: 0px;
  position: absolute;
  height: 154px;
  top: 44px;
  .custom-style-selector,
  .custom-layer-selector,
  .custom-maplayer-selector {
    padding: 4px 0;
    .svg-container {
      display: grid;
      box-shadow: 0 6px 12px 0 #00000029;
      height: 36px;
      width: 36px;
      padding: 0;
      border-radius: 0;
      background-color: #f7f7f7;
      color: #6a7485;
      border: 0;
      align-items: center;
      justify-items: center;
      &:hover {
        cursor: pointer;
        svg {
          fill: #2473bd;
        }
        .multi-path {
          path {
            fill: #2473bd;
          }
        }
      }
    }
  }
}

.custom-tooltip-class {
  height: 30px;
  background-color: #1869b5 !important;
  color: #ffffff !important;
  font-size: 10px !important;
  padding: 7px 18px !important;
  font-weight: 400 !important;
}

.toggle-3d > .map-control-button,
.show-legend {
  height: 36px !important;
  width: 36px !important;
}

.map-control > .show-legend {
  position: absolute;
  top: 188px;
}
