.style-options {
  position: absolute;
  width: 120px;
  height: 120px;
  right: 55px;
  top: 16px;
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;

  .style-option-wrapper {
    cursor: pointer;
    display: grid;
    grid-template-columns: 40px auto;
    &:hover {
      background-color: rgb(190, 190, 190);
    }
    img {
      user-select: none;
      align-self: center;
      width: 40px;
      border-radius: 5px;
      margin-left: 5px;
    }
    div {
      user-select: none;
      align-self: center;
      text-align: end;
      margin-right: 5px;
      font-size: 12px;
    }
  }
}
