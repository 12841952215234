.button-wrapper {
  display: flex;
  justify-content: space-between;
}

.switch-container {
  display: grid;
  color: #212121;
  font-weight: 400;
  grid-template-columns: 1fr 1fr 1fr;
  .heatmap-switch {
    justify-self: right;
    align-self: center;
  }
  .MuiSwitch-root {
    align-self: center;
    justify-self: center;
    .MuiSwitch-track {
      background-color: #0b74c0;
    }
    .MuiSwitch-colorSecondary.Mui-checked,
    .MuiSwitch-switchBase {
      color: #0b74c0;
    }
  }
  .locations-switch {
    justify-self: left;
    align-self: center;
  }
}

.zone-selector {
  height: 100%;
  margin-top: 12px;
  .active {
    border: solid 2px rgba(1, 140, 210, 0.75);
    border-radius: 5px;
  }

  .country-image:hover,
  .area-image:hover {
    cursor: pointer;
    // transform: scale(1.05);
    // transition: all 0.3s;
  }
  .country-title,
  .zone-title {
    font-family: "Lato";
    font-size: 16px;
  }

  .button-wrapper {
    padding-bottom: 20px;
    grid-column: 1 / 3;
    align-self: end;
  }
}

.previous-button {
  -webkit-box-align: center;
  -webkit-box-pack: center;
  align-items: center;
  background-color: white;
  border-radius: 0px;
  border: 1px solid #009de9;
  box-sizing: border-box;
  color: #009de9;
  cursor: pointer;
  display: grid;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  justify-content: center;
  letter-spacing: 0.3px;
  line-height: 14px;
  margin: 12px 0;
  opacity: 1;
  outline: 0px;
  padding: 12px 24px;
  pointer-events: all;
  text-align: center;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
}

.previous-button:hover {
  color: white;
  background-color: #009de9;
}

.run-button {
  box-sizing: border-box;
  -webkit-box-align: center;
  align-items: center;
  background-color: #009de9;
  border-radius: 0px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: grid;
  font-size: 14px;
  font-weight: 400;
  font-family: "Lato";
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.3px;
  line-height: 14px;
  outline: 0px;
  text-align: center;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  width: 78px;
  height: 44px;
  opacity: 1;
  pointer-events: all;
  padding: 12px 24px;
  border: 0px;
  margin: 12px 0;
}

.run-button:hover {
  background-color: #0074ac;
}

// .add {
//   position: absolute;
//   bottom: 200px;
//   left: 50%;
//   transform: translateX(-50%);
// }

// .be {
//   position: absolute;
//   bottom: 150px;
//   left: 50%;
//   transform: translateX(-50%);
// }

.renewable-container {
  padding-bottom: 20px;
  height: 100%;
  display: grid;
  grid-template-rows: auto auto;
  .renewable-tech-panel {
    [role="radiogroup"] .MuiTypography-root {
      font-size: 16px;
    }
    .MuiFormControlLabel-root {
      height: 25px;
    }
  }
  .button-wrapper {
    padding-bottom: 20px;
    align-self: end;
  }
}
.renewable-sizing-container {
  height: 100%;
  display: grid;
  grid-template-rows: auto auto;
  .renewable-sizing-panel {
    display: grid;
    grid-template-rows: 30px auto;
    div[role="radiogroup"] > .MuiFormControlLabel-root {
      height: 25px;
    }
    .slider-container {
      display: grid;
      justify-self: center;
      width: 100%;
      margin: 10px auto;
      .MuiSlider-markLabel {
        color: rgba(0, 0, 0, 0.87);
      }
      [data-index="1"] {
        transform: translateX(-100%);
      }
      [data-index="0"] {
        transform: translateX(0%);
      }
      .tooltip-slider {
        border: solid 1px white;
      }
    }
  }
  .button-wrapper {
    padding-bottom: 20px;
    align-self: end;
  }
}

.exclusion-panel {
  display: grid;
  height: 100%;
  grid-template-rows: auto auto;
  padding-bottom: 20px;

  margin-top: -12px;
  .MuiFormControlLabel-root {
    margin-bottom: 0px !important;
    .MuiCheckbox-root {
      padding: 9px 2px 9px 0px;
    }
    .custom-13pxlabel + .MuiFormControlLabel-label {
      font-size: 13px !important;
    }
  }
  .check-and-text-div {
    display: grid;
    grid-template-columns: 80% 20%;

    .MuiFormControl-root {
      position: relative;
      bottom: 4px;
      .MuiFormLabel-root {
        font-size: 10px;
        font-weight: bold;
      }
      .MuiFormLabel-root.Mui-focused {
        font-size: 9px;
        font-weight: bold;
        color: #009de9;
      }
      .MuiInputBase-root {
        width: 80px;
        margin-top: 5px;
        &:after {
          border-bottom: 2px solid #009de9 !important;
        }
      }
    }
  }
  .button-wrapper {
    padding-bottom: 20px;
    align-self: end;
  }

  /*
    special style to make the last two options not too long
  */
}

.advanced-lcoe-panel-container {
  display: grid;
  height: 100%;
  grid-template-rows: auto auto;
  .advanced-lcoe-panel {
    display: grid;
    .icon {
      width: 20px;
    }
    #capex-label {
      margin-bottom: 5px;
      font-family: "Lato";
      font-size: 12px;
      p {
        font-family: "Lato";
        font-size: 12px;
        margin-bottom: 0px;
      }
      p:nth-child(1) {
        font-size: 14px;
      }
    }
    #opex-label {
      margin: 5px 0;
      font-family: "Lato";
      font-size: 12px;
    }
    #capex-div,
    #opex-div {
      display: grid;
      grid-template-columns: 30px auto 30px auto;
      align-items: center;
      .MuiInputBase-root {
        width: 80px;
        &:after {
          border-bottom: 2px solid #009de9 !important;
        }
      }
    }
    #opex-div {
      margin-bottom: 5px;
    }
    #wacc-div,
    #lifetime-div {
      width: 100px;
      .MuiFormControl-root {
        .MuiInputBase-root {
          width: 100px;
          &:after {
            border-bottom: 2px solid #009de9 !important;
          }
        }
      }
    }

    .criteria-label {
      font-family: "Lato";
      font-size: 14px;
    }

    #criteria-div {
      div[role="radiogroup"] > .MuiFormControlLabel-root {
        height: 25px;
      }
      .MuiInputBase-root {
        width: 100px;

        &:after {
          border-bottom: 2px solid #009de9 !important;
        }
      }
    }

    .run {
      justify-self: center;
    }
  }
  .button-wrapper {
    padding-bottom: 20px;
    align-self: end;
  }
}

.side-bar {
  box-shadow: none !important;
}

.export-container {
  :first-child {
    display: none !important;
  }
  min-height: 40px;
}
